<template>
    <ion-page>
        <ion-card>
            <ion-card-header class="ion-text-center">
                <img alt="Dealer Incident Reporting" src="assets/logo.png">
                <ion-card-title>Login</ion-card-title>
            </ion-card-header>

            <ion-card-content class="ion-text-center">
                <div v-if="error" class="alert alert-warning">
                    <h5>{{ error }}</h5>
                </div>
                <div v-if="msInitiated" class="alert alert-info">
                    <h5>Waiting for Microsoft authentication to complete.</h5>
                    <ion-button @click="msRetry" color="dark">
                        Retry signing in with Microsoft
                    </ion-button>
                    <ion-button @click="msCancel" color="warning">
                        Cancel
                    </ion-button>
                </div>
                <template v-else>
                    <form @submit.prevent="submit">
                        <ion-item>
                            <ion-label>Email</ion-label>
                            <ion-input type="email" v-model="email" required></ion-input>
                        </ion-item>
                        <ion-item>
                            <ion-label>Password</ion-label>
                            <ion-input type="password" v-model="password" required></ion-input>
                        </ion-item>
                        <ion-button color="primary" type="submit">Login</ion-button>
                    </form>
                    <ion-button @click="msLogin" color="dark" :disabled="msInitiated">
                        Sign in with Microsoft
                    </ion-button>
                </template>
            </ion-card-content>
        </ion-card>
    </ion-page>
</template>

<script>
import { IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonInput, IonButton } from '@ionic/vue';
import Api from '@/services/Api';
import { InAppBrowser } from '@ionic-native/in-app-browser'

export default {
    name: 'Login',
    components: { IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonInput, IonButton },
    data(){
        return {
            email: null,
            password: null,
            error: false,
            msInitiated: false,
            token: null,
            validating: false,
            interval: null,
        };
    },
    methods: {
        async submit(){
            const response = await Api.login(this.email, this.password);
            if(response.success){
                this.$store.commit('authenticate', { token: response.token.id, user: response.user });
                await this.$router.push({ name: 'dashboard' });
            } else {
                this.error = response.error;
            }
        },
        generateUUID(){
            let d = new Date().getTime();
            const d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
                let r = Math.random() * 16;
                r = (d > 0) ? ((d + r)%16 | 0) : ((d2 + r)%16 | 0);
                d = (d > 0) ? Math.floor(d/16) : Math.floor(d2/16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        },
        msCancel(){
            this.msInitiated = false;
            this.token = null;
            if(this.interval){
                clearInterval(this.interval);
            }
        },
        async msLogin(){
            this.msInitiated = true;
            this.token = this.generateUUID();
            const iab = InAppBrowser.create(`https://staging.dealerincidentreporting.com/auth/microsoft/redirect?token=${this.token}`);
            this.interval = setInterval(async () => {
                if(!this.validating){
                    this.validating = true;
                    try {
                        const response = await Api.validateToken(this.token);
                        if(response.success){
                            clearInterval(this.interval);
                            this.interval = null;
                            iab.close();
                            this.$store.commit('authenticate', { token: response.token.id, user: response.user });
                            this.validating = false;
                            await this.$router.push({ name: 'dashboard' });
                        }
                    } catch (e){
                        //do nothing
                    } finally {
                        this.validating = false;
                    }
                }
            }, 3000);
        },
        async msRetry(){
            this.msCancel();
            await this.msLogin();
        },
    }
}
</script>
